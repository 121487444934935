<template>
  <div>
    <v-card>
      <v-card-title>
        Fitness Exercises Library
        <v-spacer />

        <v-btn v-if="selected.length > 0" class="mr-2 mb-2" color="secondary" @click="showBulkStatusDialog">
          UPDATE SELECTED ({{ selected.length }})
        </v-btn>

        <v-btn class="mb-2" color="primary" dark @click="showEditDialog(blankExercise)"> CREATE </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" offset-md="8">
            <v-text-field
              v-model="dtSearchQuery"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        v-model="selected"
        :headers="dtHeaders"
        :items="exercises"
        :search="dtSearchQuery"
        :expanded.sync="dtExpanded"
        :loading="isLoading"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [15, 30, 45] }"
        show-select
        single-expand
        show-expand
        item-key="id"
      >
        <template v-slot:item.thumbnail="{ item }">
          <v-img-ex :src="item.thumbnail" max-width="80" max-height="80" />
        </template>

        <template v-slot:item.thumbnailFemale="{ item }">
          <v-img-ex :src="item.thumbnailFemale" max-width="80" max-height="80" />
        </template>

        <template v-slot:item.tags="{ item }">
          <v-chip>
            {{ item.tags.length }}
          </v-chip>
        </template>

        <template v-slot:item.searchTags="{ item }">
          <v-chip>
            {{ item.searchTags.length }}
          </v-chip>
        </template>

        <template v-slot:item.video="{ item }">
          <div class="d-flex">
            <v-icon v-if="item.video.url.length" color="success">
              {{ icons.mdiGenderMale }}
            </v-icon>

            <v-icon v-if="item.videoFemale.url.length" color="warning">
              {{ icons.mdiGenderFemale }}
            </v-icon>
          </div>
        </template>

        <template v-slot:item.tips="{ item }">
          <v-chip>
            {{ item.tips.length }}
          </v-chip>
        </template>

        <template v-slot:item.time="{ item }">
          <v-chip> {{ item.time }}m </v-chip>
        </template>

        <template v-slot:item.countdownTime="{ item }">
          <v-chip> {{ item.countdownTime }}s </v-chip>
        </template>

        <template v-slot:item.active="{ item }">
          <v-chip :color="item.active ? 'success' : 'error'" dark>
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="showEditDialog(item)">
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon @click="showDeleteConfirmationDialog(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <exercise-details :item="item" />
          </td>
        </template>
      </v-data-table>
    </v-card>

    <delete-dialog
      v-model="isDeleteDialogVisible"
      :item="exercise"
      :is-loading="isLoading"
      @cancel="onDeleteCanceled"
      @delete="onDeleteConfirmed"
    />

    <exercise-edit-dialog
      v-if="isEditDialogVisible"
      v-model="isEditDialogVisible"
      :item="exercise"
      :is-loading="isLoading"
      @cancel="onEditCanceled"
      @save="onEditSave"
    />

    <v-dialog v-model="isBulkStatusDialogVisible" max-width="500">
      <v-card>
        <v-card-title>Update Status for Selected Items</v-card-title>
        <v-card-text>
          <p>You are about to update the status of {{ selected.length }} selected item(s).</p>
          <v-select
            v-model="bulkStatusValue"
            :items="[
              { text: 'Mark as Active', value: true },
              { text: 'Mark as Inactive', value: false },
            ]"
            label="Select Status"
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="isBulkStatusDialogVisible = false">Cancel</v-btn>
          <v-btn color="primary" :loading="isBulkUpdating" @click="updateBulkStatus"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiGenderFemale, mdiGenderMale, mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { onMounted, ref } from 'vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import ExerciseDetails from '@/views/exercises/partials/ExerciseDetails.vue'
import ExerciseEditDialog from '@/views/exercises/partials/ExerciseEditDialog.vue'
import VImgEx from '@/components/VImgEx.vue'
import useExercises from '@/composables/api/exercises'
import useUniqueKey from '@/composables/uniqueKey'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'

export default {
  components: {
    DeleteDialog,
    ExerciseDetails,
    ExerciseEditDialog,
    VImgEx,
  },

  setup() {
    const {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,
      blankExercise,
      exercise,
      exercises,
      isLoading,
      createExercise,
      deleteExercise,
      fetchExercises,
      updateExercise,
      updateManyExercises,
    } = useExercises()

    const { showSnackbar } = useSnackbar()
    const { uniqueKey } = useUniqueKey()

    const isDeleteDialogVisible = ref(false)
    const isEditDialogVisible = ref(false)
    const selected = ref([])
    const isBulkStatusDialogVisible = ref(false)
    const bulkStatusValue = ref(true)
    const isBulkUpdating = ref(false)

    onMounted(async () => {
      await fetchExercises('fitness')
    })

    const showDeleteConfirmationDialog = exerciseItem => {
      exercise.value = exerciseItem
      isDeleteDialogVisible.value = true
    }

    const onDeleteCanceled = () => {
      exercise.value = structuredClone(blankExercise)
      isDeleteDialogVisible.value = false
    }

    const onDeleteConfirmed = async exerciseItem => {
      await deleteExercise(exerciseItem)
      await fetchExercises('fitness')

      isDeleteDialogVisible.value = false
    }

    const showEditDialog = exerciseItem => {
      const item = structuredClone(exerciseItem)

      if (!item.uniqueKey) {
        item.uniqueKey = uniqueKey()
      }

      if (!item.id) {
        item.category = 'fitness'
      }

      exercise.value = item
      isEditDialogVisible.value = true
    }

    const onEditCanceled = () => {
      exercise.value = structuredClone(blankExercise)
      isEditDialogVisible.value = false
    }

    const onEditSave = async exerciseItem => {
      try {
        if (!exerciseItem.id) {
          await createExercise(exerciseItem)
          await fetchExercises('fitness')
        } else {
          await updateExercise(exerciseItem)
          await fetchExercises('fitness')
        }

        isEditDialogVisible.value = false
      } catch (error) {
        console.error(error)
      }
    }

    const showBulkStatusDialog = () => {
      isBulkStatusDialogVisible.value = true
    }

    const updateBulkStatus = async () => {
      if (selected.value.length === 0) return

      isBulkUpdating.value = true

      try {
        await updateManyExercises(
          'FITNESS_EXERCISE',
          'active',
          bulkStatusValue.value,
          selected.value.map(item => item.id),
        )

        await fetchExercises('fitness')

        selected.value = []
        isBulkStatusDialogVisible.value = false
      } catch (error) {
        console.error(error)
      } finally {
        isBulkUpdating.value = false
      }
    }

    return {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,

      blankExercise,
      exercises,
      exercise,
      selected,

      isBulkStatusDialogVisible,
      bulkStatusValue,
      isBulkUpdating,
      showBulkStatusDialog,
      updateBulkStatus,

      isDeleteDialogVisible,
      showDeleteConfirmationDialog,
      onDeleteCanceled,
      onDeleteConfirmed,

      isEditDialogVisible,
      showEditDialog,
      onEditCanceled,
      onEditSave,

      isLoading,

      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiGenderFemale,
        mdiGenderMale,
        mdiMagnify,
      },
    }
  },
}
</script>
